@use '@angular/material' as mat;

@mixin mobile-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $zonar-gray: map-get($theme, primary);

  .list-of-cards-container {

    .result-card-container {
      @include mat.elevation(3);
      margin-bottom: 1.5em;
      padding: 0;

      .mat-card-header {
        .mat-card-header-text {
          margin: 0;

          .mat-card-title {
            font-size: 0.85rem;
            color: mat.get-color-from-palette($primary, 600);

            .severity {
              border-top-left-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 2.25em;
              width: 2.25em;
              margin-right: 1em;

              .mat-icon {
                font-size: 1rem;
                height: fit-content;
                width: fit-content;
              }
              .custom-icon-critical {
                color: inherit;
              }

              .custom-icon-minor {
                color: black;
              }

              &.critical {
                background-color: mat.get-color-from-palette($warn, default);
                color: mat.get-color-from-palette($primary, 100);
              }

              &.minor {
                background-color: mat.get-color-from-palette($warn, 200);
                color: mat.get-color-from-palette($primary, 100);
              }
            }

            .name,
            .timestamp {
              padding-top: 0.5em;

              &::before {
                font-family: 'Material Icons';
                margin-right: 0.35em;
                position: relative;
                top: 0.1em;
              }
            }
            .name::before {
              content: 'local_shipping';
            }

            .timestamp::before {
              content: 'access_time';
            }
          }
        }
      }

      .mat-card-content {
        @include mat.typography-level($zonar-default-typography, body-1);
        font-size: 1rem;
        padding: 0 0.75em;

        .condition {
          @include mat.typography-level(
            $zonar-default-typography,
            subheading-1
          );
          font-size: 1.25rem;
          margin: 0;
        }

        .mat-expansion-panel {
          @include mat.elevation(0);

          .mat-expansion-panel-body {
            padding: 1.5em 0 0;

            .expand-item {
              border-top: 1px solid
                mat.get-color-from-palette($primary, lighter);
              padding: 0.75em 0;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 0;

              .label {
                text-transform: uppercase;
                color: mat.get-color-from-palette($zonar-gray);
              }

              .value {
                font-weight: bold;
              }

              &.issue-details {
                .value {
                  .key {
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }

        .list-of-cards-skeleton-container {
          .mat-card {
            margin-bottom: 1.5em;
          }
        }

        // Adding some styles to ensure that the placeholder element does not affect the display of the list
        .scroll-end-placeholder {
          position: relative;
          height: 200px;
          top: -200px;
          width: 100%;
          pointer-events: none;
        }
      }

      .list-of-cards-skeleton-container {
        .mat-card {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}
