@use '@angular/material'as mat;

@import '../../components/card/card.theme';
@import '../../components/mobile-table/mobile-table.component.theme';
@import '../../components/mobile-drawer/mobile-drawer.component.theme';
@import '../../components/asset-table/asset-table.theme';
@import '../../components/page-title/page-title.theme';

@mixin overview-container-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include card-theme($theme);
  @include mobile-table-theme($theme);
  @include mobile-drawer-theme($theme);
  @include asset-table-theme($theme);
  @include page-title-theme($theme);
  

  .overview-container {
    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }

    .mat-tab-nav-panel {
      position: relative;
    }

    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($foreground, base);
        text-decoration: none;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
  }

}