@use '@angular/material'as mat;

@mixin asset-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $disabled-background: #e0e0e0; // Adjust as needed
  $disabled-color: #9e9e9e; // Adjust as needed

  .asset-table-container {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 0;

    .mat-card-header .mat-card-title {
      padding: 0 1rem;
    }

    .table-header-container {
      background-color: mat.get-color-from-palette($primary, 100);
      margin-left: 1.25rem;
      margin-right: 2rem;

      .middle-content-container {
        visibility: hidden;
        display: none;
        height: 0;
      }
    }

    .mat-table {
      min-height: 10rem;

      .mat-header-row {
        border-bottom-width: 2px;
      }

      .mat-header-cell {
        text-transform: uppercase;
        border-bottom: unset;
        text-align: left;
      }

      .mat-sort-header-content {
        text-transform: uppercase;
        text-align: left;
      }

      .mat-cell {

        span,
        div {
          font-size: mat.font-size($zonar-default-typography, 'body-2');
          white-space: unset;
          word-wrap: break-word;
        }

        .mat-table {
          min-height: unset;
          background-color: mat.get-color-from-palette($accent, 100);

          .mat-row,
          .mat-header-row,
          .mat-footer-row,
          .mat-header-cell,
          .mat-cell,
          .mat-footer-cell {
            border-bottom-width: 0;
          }
        }
      }

      .mat-column-nested {
        justify-content: center;
        max-width: 1.25rem;

        &.mat-cell {
          span {
            font-size: 125%;
            text-transform: lowercase;
          }
        }

        .material-icons {
          cursor: pointer;
        }
      }

      .mat-error {
        background-color: transparent;

        .error-card {
          margin: 0;
          @include mat.elevation(0);
        }
      }

      .mat-paginator-container {
        padding: 0 1.5rem;
      }

      .spinner-container {
        display: flex;
        justify-content: center;
        transform: translateY(10vh);

        .mat-progress-spinner {
          position: absolute;
        }
      }
    }
  }
}